import find from 'lodash/find'
/**
 * Enum for Amazon sales order status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const amzSalesStatusEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'All Statuses' },
  Shipped: { key: 'Shipped', value: 'Shipped', text: 'Shipped' },
  Pending: { key: 'Pending', value: 'Pending', text: 'Pending' },
  Cancelled: { key: 'Cancelled', value: 'Cancelled', text: 'Cancelled' },
  Replacement: { key: 'Replacement', value: 'Replacement', text: 'Replacement' },
  Returned: { key: 'Returned', value: 'Returned', text: 'Returned' },
})

function getAmzSalesStatusEnum(predicate, predicateKey = 'key') {
  const result = find(amzSalesStatusEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getAmzSalesStatusList() {
  const options = []
  Object
    .keys(amzSalesStatusEnum)
    .map(key => options.push(
      {
        value: amzSalesStatusEnum[key].value,
        text: amzSalesStatusEnum[key].text,
      },
    ))
  return options
}

export default amzSalesStatusEnum

export {
  getAmzSalesStatusEnum,
  getAmzSalesStatusList,
}
