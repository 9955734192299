<template>
  <dx-util-popup
    ref="infoPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="true"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="infoPopupShown"
    @hidden="infoPopupHidden"
  >
    <div>
      <DxAccordion
        id="accordion-container"
        :data-source="questions"
        :collapsible="true"
        :multiple="true"
        :animation-duration="450"
      >
        <template #title="{ data }">
          {{ data.title }}
        </template>
        <template #item="{ data }">
          {{ data.content }}
        </template>
      </DxAccordion>
    </div>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import DxAccordion from 'devextreme-vue/accordion'

export default {
  components: {
    DxAccordion,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popupTitle: '',
      questions: [
        {
          title: 'How is ROI calculated?',
          content: 'It is calculated by this formula: Average Item Price / Unit COGS',
        },
        {
          title: 'Why Amazon Fees are 0?',
          content: 'Amazon Fees are reflected to your account in one day by Amazon and we are pulling the data once a day.',
        },
      ],
    }
  },
  computed: {
    infoPopup() {
      return this.$refs.infoPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.infoPopup.show()
      },
    },
  },
  methods: {
    infoPopupShown() {
      this.popupTitle = 'FAQs about Amazon Sales Report'
    },
    infoPopupHidden() {
    // Clear form when popup is closed
    },
    closePopup() {
      this.infoPopup.hide()
    },
    handleSubmit() {
      this.$emit('emit-form-saved')
      this.closePopup()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
