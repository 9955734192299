import find from 'lodash/find'
/**
 * Enum for Amazon sales order status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const amzIsBusinessOrderEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'All Order Types' },
  true: { key: 'true', value: 'true', text: 'Business' },
  false: { key: 'false', value: 'false', text: 'Regular' },
})

function getAmzIsBusinessOrderEnum(predicate, predicateKey = 'key') {
  const result = find(amzIsBusinessOrderEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getAmzIsBusinessOrderList() {
  const options = []
  Object
    .keys(amzIsBusinessOrderEnum)
    .map(key => options.push(
      {
        value: amzIsBusinessOrderEnum[key].value,
        text: amzIsBusinessOrderEnum[key].text,
      },
    ))
  return options
}

export default amzIsBusinessOrderEnum

export {
  getAmzIsBusinessOrderEnum,
  getAmzIsBusinessOrderList,
}
