<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 card px-half">
        <dx-data-grid
          id="amazonReportsGrid"
          ref="amazonGridRef"
          height="calc(100vh - 200px)"
          :data-source="amazonReportsDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @option-changed="onOptionChanged"
          @content-ready="onContentReady"
          @cell-dbl-click="onCellDblClick"
          @toolbar-preparing="onToolbarPreparing($event)"
          @editor-preparing="onEditorPreparing"
        >
          <dx-search-panel
            :highlight-search-text="true"
            :visible="true"
            :width="200"
            :text="filter.q"
            placeholder="Search..."
          />
          <dx-remote-operations :paging="true" :filtering="true" :sorting="true" />
          <dx-selection
            show-check-boxes-mode="always"
            select-all-mode="page"
            :allow-select-all="true"
            :width="10"
            mode="multiple"
          />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <dx-column data-field="title" cell-template="titleCellTemplate" />
          <template #titleCellTemplate="{data}">
            <item-info
              :id="data.data.productId"
              :title="data.data.title"
              :asin="data.data.asin"
              :upc="data.data.upc"
              :msku="data.data.msku"
              :image-url="data.data.smallImage"
              :large-image-url="data.data.largeImage"
              :rank="data.data.amzRank"
              :is-printable="false"
              :is-truncated="false"
              :item-info-visible="false"
              @on-emit-catalog-item-info="openProductDetails"
            />
          </template>
          <dx-column data-field="orderId" :width="220" cell-template="orderIdTemplate" />
          <template #orderIdTemplate="{data}">
            <a target="_blank" :href="`https://sellercentral.amazon.com/orders-v3/order/${data.value}`">
              {{ data.data.orderId }}
            </a>
          </template>
          <dx-column data-field="itemCount" caption="Quantity" :width="100" />
          <dx-column data-field="status" :width="140" cell-template="statusTemplate" />
          <dx-column data-field="purchaseDate" width="140" data-type="date" :format="'yyyy-MM-dd HH:mm'" />
          <template #statusTemplate="{data}">
            <div v-if="data.value" :class="resolveStatus(data.value)">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="marketplace" :width="140" />
          <dx-column data-field="channel" :width="140" cell-template="channelTemplate" />
          <template #channelTemplate="{data}">
            <div :class="resolveChannel(data.value)">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="isBusinessOrder" caption="Business Order" :width="140" cell-template="orderBusinessTypeTemplate" />
          <template #orderBusinessTypeTemplate="{data}">
            <div :class="resolveOrderBusinessType(data.value)">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="totalSales" caption="Order Price" :width="110" :format="{ type: 'currency', precision: 2 }" cell-template="orderPriceTemplate" />
          <template #orderPriceTemplate="{data}">
            <div class="">
              <div v-if="data.data.amzShipping > 0" :id="`cost1-${data.data.id}`">
                <a href="#" @click="togglePopover(data, 'orderTotal')">
                  <span v-if="data.value > 0" class="underline_txt">
                    {{ getCurrencyFormat(data.value + data.data.amzShipping) }}
                  </span>
                </a>
              </div>
              <div v-else>
                {{ getCurrencyFormat(data.value) }}
              </div>
            </div>
          </template>
          <dx-column data-field="totalCOGS" caption="Buy Cost" :width="110" cell-template="buyCostTemplate" />
          <template #buyCostTemplate="{data}">
            <div class="">
              <span v-if="data.value > 0" class="text-danger d-flex justify-content-around w-100">
                (-{{ getCurrencyFormat(data.value) }})
                <p-icon name="bi-pencil-square" role="button" color="warning" @click.native="openBuyCostUpdater(data.data)" />
              </span>
              <span v-else class="text-secondary d-flex justify-content-around w-100">
                {{ getCurrencyFormat(data.value) }}
                <p-icon name="bi-pencil-square" role="button" color="secondary" @click.native="openBuyCostUpdater(data.data)" />
              </span>
            </div>
          </template>
          <dx-column data-field="totalCosts" caption="Amazon Fees" :width="110" cell-template="totalCostTemplate" />
          <template #totalCostTemplate="{data}">
            <div :id="`cost1-${data.data.id}`">
              <a href="#" @click="togglePopover(data, 'totalCosts')">
                <span v-if="data.value > 0" class="underline_txt">
                  (-{{ getCurrencyFormat(data.value) }})
                </span>
                <span v-else class="text-secondary">
                  {{ getCurrencyFormat(data.value) }}
                </span>
              </a>
            </div>
          </template>
          <dx-column data-field="otherCosts" caption="Other Fees" :width="110" cell-template="otherCostTemplate" />
          <template #otherCostTemplate="{data}">
            <div :id="`cost-${data.data.id}`">
              <a href="#" @click="togglePopover(data, 'otherCosts')">
                <span v-if="data.value > 0" class="underline_txt">
                  (-{{ getCurrencyFormat(data.value) }})
                </span>
                <span v-else class="text-secondary">
                  {{ getCurrencyFormat(data.value) }}
                </span>
              </a>
            </div>
          </template>
          <dx-column caption="Gross Income (Other Cost Excluded)" alignment="center">
            <dx-column data-field="profit" :width="140" cell-template="profitTemplate" />
            <dx-column data-field="profitPercent" caption="Profit %" :width="140" cell-template="roiTemplate" />
            <dx-column data-field="roi" caption="ROI %" :width="90" alignment="right" cell-template="roiTemplate" />
          </dx-column>
          <dx-column caption="Net Income (Other Cost Included)" alignment="center">
            <dx-column data-field="netProfit" :width="140" cell-template="profitTemplate" />
            <dx-column data-field="netProfitPercent" caption="Net Profit %" :width="140" cell-template="roiTemplate" />
            <dx-column data-field="netRoi" caption="Net ROI %" :width="90" alignment="right" cell-template="roiTemplate" />
          </dx-column>
          <template #roiTemplate="{ data }">
              <div :class="resolveByValue(data.value)">
                {{ data.value }}%
              </div>
            </template>
          <template #profitTemplate="{data}">
              <div :class="resolveByValue(data.value)">
                {{ getCurrencyFormat(data.value) }}
              </div>
            </template>
          <template #toolbarFilters>
            <div class="d-flex">
              <div class="mr-half">
                <dx-util-select-box
                  v-model="selectedStore"
                  :data-source="stores"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-half">
                <dx-util-select-box
                  v-model="filter.marketplace"
                  :data-source="marketplaces"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-half">
                <dx-util-select-box
                  v-model="filter.channel"
                  :data-source="fulfillmentMethods"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-half">
                <dx-util-select-box
                  v-model="filter.status"
                  :data-source="amzSalesStatus"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-half">
                <dx-util-select-box
                  v-model="filter.isBusinessOrder"
                  :data-source="amzIsBusinessOrderList"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <date-selection default-date="current_month" @setdates="onDateSelection" />
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
    <div class="col-md-12 px-0">
      <light-box
        :items="images"
        :index="index"
        :effect="'fade'"
        @close="index = null"
      />
    </div>
    <!--Begin:: Product Details -->
    <product-details :product-id="selectedProductId"
      :show-product="isProductDetailsVisible"
      @close="isProductDetailsVisible=false,selectedProductId=null"
    />
    <dx-util-popover
      :target="hoveredItem"
      position="right"
      :defer-rendering="false"
      :close-on-outside-click="true"
      :show-title="true"
      title="Cost Details"
      :width="400"
      :visible="isPopoverVisible"
      :show-close-button="true"
      @hiding="closePopover"
    >
      <template>
        <div>
          <cost-snapshot :cost-details="costDetails" />
        </div>
      </template>
    </dx-util-popover>
    <buy-cost-updater :component-id="buyCostUpdaterCompId" :row="selectedRow" @emit-form-saved="searchByFilter" />
    <info-popup :component-id="infoPopupCompId" />
    <div class="col-md-12 py-1 position-absolute fixed-bottom">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <p-icon name="bi-info-circle-fill" color="warning" size="32px" class="mr-1" />
        <span><strong>Beta Version: This is a beta version of this report module. It is currently being tested and its usage is for free. Some features may be limited upon the full version release and will be subject to additional charges.</strong></span>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import DateSelection from '@/views/dashboard/statistics/components/DateSelection.vue'
import AmazonSalesFilter from '@/http/models/search-filters/AmazonSalesFilter'
import { currencyFormatter } from '@core/utils/filter'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import { Notify } from '@/@robustshell/utils'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ItemInfo from '@/views/apps/outbound/fba-shipments/components/ItemInfo.vue'
// import moment from 'moment'
// import { v4 as uuidv4 } from 'uuid'
import { getMarketplaceList } from '@/enums/marketplaceEnum'
import { getFulfillmentMethodList } from '@/enums/fulfillmentType.enum'
import { getAmzSalesStatusList } from '@/enums/amzSalesStatus.enum'
import { getAmzIsBusinessOrderList } from '@/enums/amzIsBusinessOrder.enum'
import InfoPopup from '@/views/apps/reports/amazon-reports/components/InfoPopup.vue'
import { AmazonSalesDataSource } from './amazonSalesStore'

// import Pager from '@core/dev-extreme/utils/pager'
// import ReportDateRangeEnum from '@/enums'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    InfoPopup,
    'cost-snapshot': () => import('./CostSnapshot.vue'),
    'product-details': () => import('@/views/apps/product/products/components/ProductDetails.vue'),
    'light-box': () => import('vue-cool-lightbox'),
    'item-info': ItemInfo,
    'date-selection': DateSelection,
    'buy-cost-updater': () => import('./BuyCostUpdater.vue'),
  },
  mixins: [GridBase, Filters],
  data() {
    return {
      marketplaces: getMarketplaceList(),
      fulfillmentMethods: getFulfillmentMethodList(),
      amzSalesStatus: getAmzSalesStatusList(),
      amzIsBusinessOrderList: getAmzIsBusinessOrderList(),
      amazonReportsDataSource: AmazonSalesDataSource,
      filter: {
        marketplace: 'ALL',
        channel: 'ALL',
        status: 'ALL',
        isBusinessOrder: 'ALL',
        q: '',
        beginDate: null,
        endDate: null,
      },
      defaultImg: defaultImageUrl,
      selectedProductId: null,
      isProductDetailsVisible: false,
      images: [],
      index: null,
      storeId: null,
      reportItem: '',
      hoveredItem: '',
      isPopoverVisible: false,
      costDetails: {
        totalCOGS: null,
        fbaFee: null,
        referralFee: null,
        mfnShipping: null,
        prepFee: null,
        planFee: null,
        shippingFee: null,
        miscFee: null,
      },
      buyCostUpdaterCompId: '',
      selectedRow: {},
      infoPopupCompId: '',
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    hasAdminPermission() {
      return this.$can('read', 'resource_admin')
    },
    dataGrid() {
      const dataGridIns = this.$refs.amazonGridRef.instance
      return dataGridIns
    },
    dataSource() {
      const dataSource = this.dataGrid.getDataSource()
      return dataSource
    },
  },
  created() {
    const orderId = this.$route.query.orderId
    this.filter.q = orderId
    this.getStores()
  },
  mounted() {
    this.loadData()
  },
  methods: {
    openBuyCostUpdater(data) {
      this.selectedRow = data
      this.buyCostUpdaterCompId = this.$uid()
    },
    getCurrencyFormat(value) {
      return currencyFormatter(value)
    },
    openProductDetails(e) {
      this.selectedProductId = e.param
      this.isProductDetailsVisible = true
    },
    loadData() {
      AmazonSalesFilter.setDefaultFilters()
      AmazonSalesFilter.beginDate = this.beginDate
      AmazonSalesFilter.endDate = this.endDate
      const filters = AmazonSalesFilter.getFilters()
      AmazonSalesDataSource.searchValue(filters)
    },
    searchByFilter() {
      AmazonSalesFilter.storeId = this.selectedStore
      AmazonSalesFilter.beginDate = this.filter.beginDate
      AmazonSalesFilter.endDate = this.filter.endDate
      AmazonSalesFilter.marketplace = this.filter.marketplace
      AmazonSalesFilter.channel = this.filter.channel
      AmazonSalesFilter.status = this.filter.status
      AmazonSalesFilter.isBusinessOrder = this.filter.isBusinessOrder
      AmazonSalesFilter.q = this.filter.q
      const filters = AmazonSalesFilter.getFilters()
      AmazonSalesDataSource.searchValue(filters)
      AmazonSalesDataSource.reload()
    },
    onDateSelection(e) {
      this.filter.beginDate = e.beginDate
      this.filter.endDate = e.endDate
      this.searchByFilter()
    },
    resetAndRefreshDataGrid() {
      this.selectedStore = 0
      this.filter.q = ''
      const filters = AmazonSalesFilter.getFilters()
      this.dataSource.searchValue(filters)
      this.dataSource.reload()
    },
    resolveStatus(status) {
      if (status === 'Shipped' || status === 'Shipped - Delivered to Buyer') {
        return 'badge badge-success'
      }
      if (status === 'Unshipped') {
        return 'badge badge-warning'
      }
      if (status === 'Returned') {
        return 'badge badge-danger'
      }
      return 'badge badge-primary'
    },
    resolveChannel(status) {
      if (status === 'Amazon') {
        return 'badge badge-warning'
      }
      return 'badge badge-primary'
    },
    resolveOrderBusinessType(status) {
      if (status) {
        return 'badge badge-light-warning'
      }
      return 'badge badge-light-secondary'
    },
    resolveByValue(value) {
      const profit = parseFloat(value)
      if (profit > 0) {
        return 'badge badge-light-success'
      }
      if (profit < 0) {
        return 'badge badge-light-danger'
      }
      return ''
    },
    togglePopover(data, costType) {
      const rowId = data.data.id
      this.hoveredItem = `#cost-${rowId}`
      const payload = {
        totalSales: this.getCurrencyFormat(data.data.totalSales),
        fbaFee: data.data.fbafee,
        referralFee: data.data.referralFee,
        mfnShipping: data.data.mfnShipping,
        amzShipping: this.getCurrencyFormat(data.data.amzShipping),
        grandTotal: this.getCurrencyFormat(data.data.totalSales + data.data.amzShipping),
        prepFee: data.data.prepFee,
        planFee: data.data.planFee,
        shippingFee: data.data.shippingFee,
        miscFee: data.data.miscFee,
        costType: costType,
      }
      this.isPopoverVisible = true
      this.costDetails = payload
    },
    closePopover() {
      this.isPopoverVisible = false
      this.costDetails = {
        totalCOGS: 0,
        fbaFee: 0,
        referralFee: 0,
        prepFee: 0,
        planFee: 0,
        shippingFee: 0,
        miscFee: 0,
        mfnShipping: 0,
      }
    },
    // DATAGRID METHODS
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        this.dataSource.pageIndex(0)
      }
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filter.q = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filter.q = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filter.q = ''
            this.searchByFilter()
          }
        }
      }
    },
    async onDownloadReport(e) {
      AmazonSalesFilter.itemAction = this.isAll ? 'ALL' : this.itemAction
      const filters = AmazonSalesFilter.getFilters()
      const pageableQuery = 'page=0&size=10000'
      const response = await amazonReportService.salesByQuery(pageableQuery, filters)
      const data = response.content
      this.onDownloadExportData(data)
    },
    onDownloadReportSelected() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const fileDate = new Date().toDateString()
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(`Sales_${fileDate}`)
      worksheet.columns = [
        { header: 'Title', key: 'title', width: 60 },
        { header: 'Marketplace', key: 'marketplace', width: 20 },
        { header: 'Channel', key: 'channel', width: 20 },
        { header: 'Purchase Date', key: 'purchaseDate', width: 20 },
        { header: 'ASIN', key: 'asin', width: 14 },
        { header: 'UPC', key: 'upc', width: 14 },
        { header: 'MSKU', key: 'msku', width: 14 },
        { header: 'Item Count', key: 'itemCount', width: 14 },
        { header: 'Order Price', key: 'totalSales', width: 14 },
        { header: 'Buy Cost', key: 'totalCOGS', width: 14 },
        { header: 'Other Costs', key: 'otherCosts', width: 20 },
        { header: 'Plan Fee', key: 'planFee', width: 20 },
        { header: 'Shipping Fee', key: 'shippingFee', width: 20 },
        { header: 'Referral Fee', key: 'referralFee', width: 20 },
        { header: 'FBA Fee', key: 'fbafee', width: 20 },
        { header: 'MFN Fee', key: 'mfnShipping', width: 20 },
        { header: 'Gross Profit %', key: 'profitPercent', width: 14 },
        { header: 'Gross Profit', key: 'profit', width: 10 },
        { header: 'Gross ROI', key: 'roi', width: 12 },
        { header: 'Net Profit %', key: 'netProfitPercent', width: 14 },
        { header: 'Net Profit', key: 'netProfit', width: 10 },
        { header: 'Net ROI', key: 'netRoi', width: 12 },

      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:N1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.title,
          item.marketplace,
          item.channel,
          item.purchaseDate,
          item.asin,
          item.upc,
          item.msku,
          item.itemCount,
          item.totalSales,
          item.totalCOGS,
          item.otherCosts,
          item.planFee,
          item.shippingFee,
          item.referralFee,
          item.fbafee,
          item.mfnShipping,
          item.profitPercent,
          item.profit,
          item.roi,
          item.netProfitPercent,
          item.netProfit,
          item.netRoi,

        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Amazon_Sales_${fileDate}.xlsx`,
        )
      })
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Import Costs',
          type: 'default',
          onClick: () => {
            this.$router.push({ name: 'amazon-sales-upload' })
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Items',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected',
              icon: 'exportselected',
            },
          ],
          width: 140,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download All Report',
          onItemClick: arg => {
            if (arg) {
              //
            }
            if (arg.itemData.id === 'all') {
              this.onDownloadReport()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadReportSelected()
            }
          },
        },

        location: 'after',
      })

      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          visible: this.hasAdminPermission,
          icon: 'icon bi-info-circle',
          type: 'default',
          onClick: () => {
            this.infoPopupCompId = this.$uid()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.resetAndRefreshDataGrid()
          },
        },
        location: 'after',
      })
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
