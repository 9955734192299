import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import Pager from '@core/dev-extreme/utils/pager'
// import { v4 as uuidv4 } from 'uuid'
import amazonReportService from '@/http/requests/reports/amazonReportService'
// import AmazonReportFilter from '@/http/models/search-filters/AmazonReportFilter'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'purchaseDate', desc: true }]
    const pager = new Pager(options)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)

    if (loadOptions.searchValue) {
      // If searchValue is present, apply the filter
      options.filters = loadOptions.searchValue
    }
    let sortOrder = ''
    if (sort) {
      pager.setSortQuery(sort)
      sortOrder = `&sort=${pager.toCreateSortOrder()}`
    }
    const pageableString = `${pager.toCreatePageable()}${sortOrder}`
    return amazonReportService
      .salesByQuery(pageableString, options.filters)
      .then(response => {
        const data = response.content
        const totalCount = response.totalElements
        return {
          data: data,
          totalCount: totalCount,
        }
      })
  },

})

const AmazonSalesDataSource = new DataSource({
  store: store,
})

export {
  AmazonSalesDataSource,
}
